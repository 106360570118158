<template>
  <div class="guide-view" v-on:[notHotEventName]="onFullScreenClick">
    <div class="native-box">
      <WapNative space-key="GuideNative"></WapNative>
    </div>

    <div class="card-box"
      v-on:[hotEventName]="onRouterEnter('CardBtn', $event)"
      @touchstart.stop
      @click.stop
    >
      <Swipe :autoplay="1200" indicator-color="#fff">
        <SwipeItem>
          <img src="@/assets/images/guide_new/card1.png" />
        </SwipeItem>
        <SwipeItem>
          <img src="@/assets/images/guide_new/card2.png" />
        </SwipeItem>
        <SwipeItem>
          <img src="@/assets/images/guide_new/card3.png" />
        </SwipeItem>
        <SwipeItem>
          <img src="@/assets/images/guide_new/card4.png" />
        </SwipeItem>
        <SwipeItem>
          <img src="@/assets/images/guide_new/card5.png" />
        </SwipeItem>
        <SwipeItem>
          <img src="@/assets/images/guide_new/card6.png" />
        </SwipeItem>
        <SwipeItem>
          <img src="@/assets/images/guide_new/card7.png" />
        </SwipeItem>
        <SwipeItem>
          <img src="@/assets/images/guide_new/card8.png" />
        </SwipeItem>
        <SwipeItem>
          <img src="@/assets/images/guide_new/card9.png" />
        </SwipeItem>
        <SwipeItem>
          <img src="@/assets/images/guide_new/card10.png" />
        </SwipeItem>
        <SwipeItem>
          <img src="@/assets/images/guide_new/card11.png" />
        </SwipeItem>
        <template #indicator="{ active, total }">
          <div class="indicator-box">
            <div class="custom-indicator" :class="active == 0 ? 'active' : ''"></div>
            <div class="custom-indicator" :class="active == 1 ? 'active' : ''"></div>
            <div class="custom-indicator" :class="active == 2 ? 'active' : ''"></div>
            <div class="custom-indicator" :class="active == 3 ? 'active' : ''"></div>
            <div class="custom-indicator" :class="active == 4 ? 'active' : ''"></div>
            <div class="custom-indicator" :class="active == 5 ? 'active' : ''"></div>
            <div class="custom-indicator" :class="active == 6 ? 'active' : ''"></div>
            <div class="custom-indicator" :class="active == 7 ? 'active' : ''"></div>
            <div class="custom-indicator" :class="active == 8 ? 'active' : ''"></div>
            <div class="custom-indicator" :class="active == 9 ? 'active' : ''"></div>
            <div class="custom-indicator" :class="active == 10 ? 'active' : ''"></div>
          </div>
        </template>
      </Swipe>
    </div>

    <div class="finger-box"
      v-on:[hotEventName]="onRouterEnter('FingerBtn', $event)"
      @touchstart.stop
      @click.stop
    >
      <img src="@/assets/images/guide_new/finger.png" />
    </div>

    <div class="shadow-box">
      <img src="@/assets/images/guide_new/book.png" />
    </div>

    <div class="icp-box">
      <Icp></Icp>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Icp from "@/components/common/Icp.vue";
import { Swipe, SwipeItem } from 'vant';

import router from "@/router";
import { useMyStrategy } from "@/hooks/useMyStrategy";
import { Haa, OeReportType, OeReportSubType } from "@haa/fwk-h5/lib/index";
import { ref, watch } from "vue";

// sdk监听生命周期
Haa.util().useLifeCycle();

let enterTimer: number | null = null;

const clearTimer = () => {
  if (enterTimer != null) {
    clearTimeout(enterTimer);
    enterTimer = null;
  }
};

const { strategy } = useMyStrategy();
const { oeReport, openWindow } = Haa.util().useOeReport();

// 全屏点击
const onFullScreenClick = (e: Event) => {
  e?.stopPropagation?.();

  if (strategy.value?.guide_click_enable === 0) return;

  if (strategy.value?.guide_click_enable === 1) {
    onRouterEnter("FullScreen", e);
  }
};

// 跳转函数
const onRouterEnter = (msgType: string, e?: any) => {
  e?.stopPropagation?.();

  let appX, appY;

  if (e) {
    appX = e.touches ? e.touches[0].clientX : e.clientX;
    appY = e.touches ? e.touches[0].clientY : e.clientY;
    appX = Math.round(appX);
    appY = Math.round(appY);
  }

  oeReport(OeReportType.Action, OeReportSubType.BtnClick, "Guide", msgType, undefined, appX, appY);

  const result = openWindow({ skip: '1' });

  if (!!result) return;

  router.replace({
    path: "/home",
  });

  clearTimer();
};

// 31038上报处理函数
const onCriticalClick = (e: TouchEvent) => {
  let appX, appY;

  if (e) {
    appX = e.touches[0].clientX;
    appY = e.touches[0].clientY;
    appX = Math.round(appX);
    appY = Math.round(appY);
  }

  oeReport(
    OeReportType.Action,
    OeReportSubType.NonCriticalAreaClick,
    "Guide",
    "NonCriticalAreaClick",
    undefined,
    appX,
    appY
  );
};

/** 处理策略控制事件处理方式 -----------------begin */
enum EventNames {
  Touchstart = "touchstart",
  Click = "click"
}

/** 默认使用touchstart触发点击 */
let hotEventName = ref(EventNames.Touchstart);
let notHotEventName = ref(EventNames.Touchstart);

const handleEventName = (type: number) => {
  // 0：全部touch_down触发跳转(默认);
  // 1：热点区域click触发，非热点区域touch_down触发跳转；
  // 2：热点区域touch_down触发，非热点区域click触发跳转;
  // 3：全部click触发跳转；1：热点区域click触发;
  const handleMap = [
    [EventNames.Touchstart, EventNames.Touchstart],
    [EventNames.Click, EventNames.Touchstart],
    [EventNames.Touchstart, EventNames.Click],
    [EventNames.Click, EventNames.Click]
  ];

  const [_hotEventName, _notHotEventName] = handleMap[type] ?? [];

  _hotEventName && (hotEventName.value = _hotEventName);
  _notHotEventName && (notHotEventName.value = _notHotEventName);
};
/** 处理策略控制事件处理方式 -----------------end */

/** 监听策略返回情况 */
watch(
  () => strategy.value,
  (res) => {
    if (!res) return;

    // 根据策略 是否倒计时离开引导页
    if (res.guide_timeout !== -1) {
      enterTimer = setTimeout(() => {
        onRouterEnter("Timerout_" + res.guide_timeout + "s");
      }, res.guide_timeout * 1000);
    }

    // 根据策略 判断是否指定触发事件类型
    handleEventName(res.guide_used_click);
  },
  {
    immediate: true
  }
);
</script>

<style scoped lang="less">
.guide-view {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-image: url('@/assets/images/guide_new/bg.png');
  background-size: 100% 100%;

  .native-box {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    opacity: 0;
  }
  .card-box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 30px;
    transform: translateY(230px);
    img {
      width: 100%;
    }
    .indicator-box {
      width: 100%;
      position: absolute;
      top: 650px;
      left: -30px;
      display: flex;
      justify-content: center;
      .custom-indicator {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        background-color: #fff;
        margin: 0 10px;
        opacity: 0.4;
        &.active {
          opacity: 1;
        }
      }
    }
  }
  .finger-box {
    width: 140px;
    height: 160px;
    position: absolute;
    top: 950px;
    left: 250px;
    animation-name: slide;
    animation-duration: 800ms;
    animation-iteration-count: infinite;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .shadow-box {
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: end;
    img {
      width: 104%;
      transform: translateX(-2%);
    }
  }
  .icp-box {
    width: 100%;
    position: absolute;
    bottom: 30px;
  }
}
@keyframes slide {
  0% {
    transform: translateX(200px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
