<template>
    <div class="home-view">
        <div class="home-title">养生知识</div>
        <div class="tab-bar">
            <div
                v-for="item in tab"
                class="tab-bar-item"
                :class="active == item.key && 'active'"
                @click="onTabClick(item)"
            >
                <img :src="active == item.key ? item.icon_s : item.icon" />
                <span>{{ item.title }}</span>
            </div>
        </div>
        <div class="tab-content" v-slide>
            <RouterView/>
        </div>
    </div>
</template>

<script setup lang="ts">
import { Haa, OeReportType, OeReportSubType } from "@haa/fwk-h5/lib/index";
// sdk监听生命周期
Haa.util().useLifeCycle();

import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
const route = useRoute();
const tab = ref<TabItemType[]>([
    {
        title: '推荐',
        path: '/home',
        key: 'home',
        icon: require('@/assets/images/common/tab_1.png'),
        icon_s: require('@/assets/images/common/tab_1_s.png'),
    },
    {
        title: '热门',
        path: '/hot',
        key: 'hot',
        icon: require('@/assets/images/common/tab_2.png'),
        icon_s: require('@/assets/images/common/tab_2_s.png'),
    },
]);
const router = useRouter();
const onTabClick = (item: TabItemType) => {
    active.value = item.key;
    router.push(item.path);
}
const active = ref(tab.value.find(item => item.path === route.path)?.key);
</script>

<style scoped lang="less">
.home-view {
    height: 100vh;
    background-image: url('@/assets/images/common/home_bg.png');
    background-size: 100% 100%;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    .home-title {
        font-size: 35px;
        line-height: 52px;
        color: #fff;
        font-weight: 700;
        text-align: center;
    }
    .tab-bar {
        margin-top: 60px;
        display: flex;
        justify-content: center;
        .tab-bar-item {
            display: flex;
            align-items: center;
            margin: 0 20px;
            border-bottom: 6px solid transparent;
            img {
                width: 32px;
                height: 32px;
                margin-right: 10px;
            }
            span {
                font-size: 34px;
                line-height: 52px;
                color: #AFAFAF;
            }
            &.active {
                border-bottom-color: #70B4FF;
                span {
                    color: #2B9AFF;
                    font-weight: 700;
                }
            }
        }
    }
    .tab-content {
        flex: 1;
        overflow-y: auto;
    }
}
</style>
